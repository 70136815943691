const actions = {
    FETCH_PUBLISHERS : 'FETCH_PUBLISHERS',

    fetchPublishersAction: (data) => {
        return {
            type: actions.FETCH_PUBLISHERS,
            data: data
        };
    },
}

export default actions;
