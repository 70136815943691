const actions = {
    FETCH_REVIEWS    : 'FETCH_REVIEWS',
    FETCH_REVIEW      : 'FETCH_REVIEW',

    fetchReviewsAction: (data) => {
        return {
            type: actions.FETCH_REVIEWS,
            data: data
        };
    },
    fetchReviewAction: (data) => {
        return {
            type: actions.FETCH_REVIEW,
            data: data
        };
    },
}

export default actions;
