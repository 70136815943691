import actions from "./actions";

const {FETCH_ADVERTISEMENT, FETCH_ADVERTISEMENTS, RESET_ADVERTISEMENT} = actions;
const initState = {
    advertisements: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    advertisement: {_id: null}
};

const AdvertisementReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_ADVERTISEMENTS:
            return {
                ...state,
                advertisements: data
            }
        case RESET_ADVERTISEMENT:
            return {
                ...state,
                advertisement: initState.advertisement,
            };
        case FETCH_ADVERTISEMENT:
            return {
                ...state,
                advertisement: data,
            };
        default:
            return state;
    }
}

export default AdvertisementReducer;
