import actions from './actions';
import {RequestService as req} from '../../services/requestService';
import { Constants } from '../../config/constants';

const {
    LoadingStart, LoadingEnd,
    fetchRolesAction, fetchUserAction, fetchUsersAction,
    fetchLocationsAction, fetchCitiesAction, fetchCountriesAction,
    fetchCategoriesAction, fetchHighlightsAction, fetchTagsAction,
    fetchRoyaltyUsersAction,fetchAuthorsAction, fetchNarratorsAction, fetchPublishersAction, fetchProducersAction,
    fetchPaymentsAction, fetchSubscriptionsAction
} = actions;

const loadingStart = () => async dispatch => dispatch(LoadingStart());
const loadingEnd = () => async dispatch => dispatch(LoadingEnd());

const fetchRoles = () => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}roles`,
            auth: 'bearer'
        }, (cb) => dispatch(fetchRolesAction(cb)))
    };
};

const fetchUser = (queries, action = null) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}user`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchUserAction(cb))
            if (action) action(cb)
        })
    };
};

const fetchUsers = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}users`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchUsersAction(cb)))
    };
}

const fetchCountries = () => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}countries`,
            auth: 'bearer'
        }, (cb) => dispatch(fetchCountriesAction(cb)))
    };
};

const fetchCities = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}cities`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchCitiesAction(cb)))
    };
};

const fetchLocations = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}locations`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchLocationsAction(cb)))
    };
};

const fetchCategories = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}categories`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchCategoriesAction(cb)))
    };
};

const fetchRoyaltyUsers= (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}royaltyUsers`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchRoyaltyUsersAction(cb)))
    };
};

const fetchAuthors = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}authors`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchAuthorsAction(cb)))
    };
};

const fetchNarrators = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}narrators`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchNarratorsAction(cb)))
    };
};

const fetchPublishers = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}publishers`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchPublishersAction(cb)))
    };
};

const fetchProducers = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}producers`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchProducersAction(cb)))
    };
};

const fetchHighlights = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}highlights`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchHighlightsAction(cb)))
    };
};

const fetchTags = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}tags`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchTagsAction(cb)))
    };
};

const fetchPayments = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}payments`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchPaymentsAction(cb)))
    };
};

const fetchSubscriptions = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}subscriptions`,
            queries: queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSubscriptionsAction(cb)))
    };
}

export {
    loadingStart, loadingEnd,
    fetchRoles, fetchUser, fetchUsers,
    fetchCountries, fetchCities, fetchLocations,
    fetchCategories, fetchHighlights, fetchTags,
    fetchAuthors, fetchNarrators, fetchPublishers, fetchProducers, fetchRoyaltyUsers,
    fetchPayments, fetchSubscriptions
};
