import actions from "./actions";

const {FETCH_NARRATORS} = actions;
const initState = {
    narrators: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
};

const NarratorsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_NARRATORS:
            return {
                ...state,
                narrators: data
            }
        default:
            return state;
    }
}

export default NarratorsReducer;
