const actions = {
    FETCH_SUBSCRIPTIONS     : 'FETCH_SUBSCRIPTIONS',
    FETCH_SUBSCRIPTION      : 'FETCH_SUBSCRIPTION',
    RESET_SUBSCRIPTION      : 'RESET_SUBSCRIPTION',

    fetchSubscriptionsAction: (data) => {
        return {
            type: actions.FETCH_SUBSCRIPTIONS,
            data: data
        };
    },
    resetSubscriptionFormAction: () => {
        return {
            type: actions.RESET_SUBSCRIPTION,
            data: null
        };
    },
    fetchSubscriptionAction: (data) => {
        return {
            type: actions.FETCH_SUBSCRIPTION,
            data: data
        };
    },
}

export default actions;
