import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Categories = lazy(() => import('../../container/webSetup/categories/index'));
const Countries = lazy(() => import('../../container/webSetup/countries/index'));
const Cities = lazy(() => import('../../container/webSetup/cities/index'));
const Locations = lazy(() => import('../../container/webSetup/locations/index'));
const Tags = lazy(() => import('../../container/webSetup/tags/index'));
const Banners = lazy(() => import('../../container/webSetup/banners/index'));
const Highlights = lazy(() => import('../../container/webSetup/highlights/index'));

const WebSetupRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/categories`} component={Categories}/>
            <Route path={`${path}/countries`} component={Countries}/>
            <Route path={`${path}/cities`} component={Cities}/>
            <Route path={`${path}/locations`} component={Locations}/>
            <Route path={`${path}/tags`} component={Tags}/>
            <Route path={`${path}/banners`} component={Banners}/>
            <Route path={`${path}/highlights`} component={Highlights}/>
        </Switch>
    );
};

export default WebSetupRoutes;
