import React from "react";
import { Menu } from "antd";
import { NavLink, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { Scope } from "../services/scopeService";
import { Auth } from "../services/authService";

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
    const userInfo = Auth.getUserInfo();
    const { path } = useRouteMatch();
    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split("/");

    return (
        <Menu
            mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
            theme={darkMode && "dark"}
            // // eslint-disable-next-line no-nested-ternary
            defaultSelectedKeys={
                !topMenu
                    ? [
                        `${mainPathSplit.length === 1 ? "home" : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                        }`
                    ]
                    : []
            }
            defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`] : []}
            overflowedIndicator={<FeatherIcon icon="more-vertical" />}
        >
            <Menu.Item icon={!topMenu && <FeatherIcon icon="home" />} key="dashboard">
                <NavLink to={`${path}/dashboard`}>
                    Dashboard
                </NavLink>
            </Menu.Item>
            {Scope.checkScopesParent(["um_"]) && (
                <SubMenu key="user-management" icon={!topMenu && <FeatherIcon icon="user-check" />}
                         title="User Management">
                    {Scope.checkScopes(["um_departments_index"]) && (
                        <Menu.Item key="departments">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/departments`}>
                                Departments
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["um_teams_index"]) && (
                        <Menu.Item key="teams">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/teams`}>
                                Teams
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["um_roles_index"]) && (
                        <Menu.Item key="roles">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/roles`}>
                                Roles
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["um_roles_permissions_index"]) && (
                        <Menu.Item key="roles-permissions">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/roles-permissions`}>
                                Permissions
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["um_users_index"]) && (
                        <Menu.Item key="um-users">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/users`}>
                                Users
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            )}
            {Scope.checkScopesParent(["ws_"]) && (
                <SubMenu key="web-setup" icon={!topMenu && <FeatherIcon icon="settings" />} title="Web Setup">
                    {Scope.checkScopes(["ws_categories_index"]) && (
                        <Menu.Item key="categories">
                            <NavLink onClick={toggleCollapsed} to={`${path}/web-setup/categories`}>
                                Categories
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["ws_countries_index"]) && (
                        <Menu.Item key="countries">
                            <NavLink onClick={toggleCollapsed} to={`${path}/web-setup/countries`}>
                                Countries
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["ws_cities_index"]) && (
                        <Menu.Item key="cities">
                            <NavLink onClick={toggleCollapsed} to={`${path}/web-setup/cities`}>
                                Cities
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["ws_locations_index"]) && (
                        <Menu.Item key="locations">
                            <NavLink onClick={toggleCollapsed} to={`${path}/web-setup/locations`}>
                                Locations
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["ws_tags_index"]) && (
                        <Menu.Item key="tags">
                            <NavLink onClick={toggleCollapsed} to={`${path}/web-setup/tags`}>
                                Tags
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["ws_banners_index"]) && (
                        <Menu.Item key="banners">
                            <NavLink onClick={toggleCollapsed} to={`${path}/web-setup/banners`}>
                                Banners
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["ws_highlights_index"]) && (
                        <Menu.Item key="highlights">
                            <NavLink onClick={toggleCollapsed} to={`${path}/web-setup/highlights`}>
                                Highlights
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            )}
            {Scope.checkScopesParent(["workspace_"]) && (
                <SubMenu key="workspace" icon={!topMenu && <FeatherIcon icon="briefcase" />} title="Workspace">
                    {Scope.checkScopes(["workspace_books_index"]) && (
                        <Menu.Item key="books">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/books`}>
                                Books
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["workspace_podcasts_index"]) && !userInfo.superAdmin && (
                        <Menu.Item key="podcasts">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/podcasts`}>
                                Podcasts
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["workspace_coupons_index"]) && (
                        <Menu.Item key="coupons">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/coupons`}>
                                Coupons
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["workspace_subscriptions_index"]) && (
                        <Menu.Item key="subscriptions">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/subscriptions`}>
                                Subscriptions
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["workspace_advertisements_index"]) && (
                        <Menu.Item key="advertisements">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/advertisements`}>
                                Advertisements
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["workspace_reviews_index"]) && (
                        <Menu.Item key="reviews">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/reviews`}>
                                Reviews
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["workspace_payments_index"]) && (
                        <Menu.Item key="payments">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/payments`}>
                                Payments
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["workspace_referrals_index"]) && (
                        <Menu.Item key="referrals">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/referrals`}>
                                Referrals
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["workspace_withdrawals_index"]) && (
                        <Menu.Item key="withdrawals">
                            <NavLink onClick={toggleCollapsed} to={`${path}/workspace/withdrawals`}>
                                Withdrawals
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            )}
            {Scope.checkScopesParent(["clients_"]) && (
                <SubMenu key="clients" icon={!topMenu && <FeatherIcon icon="users" />} title="Clients">
                    {Scope.checkScopes(["clients_users_index"]) && (
                        <Menu.Item key="users">
                            <NavLink onClick={toggleCollapsed} to={`${path}/clients/users`}>
                                Users
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["clients_authors_index"]) && (
                        <Menu.Item key="authors">
                            <NavLink onClick={toggleCollapsed} to={`${path}/clients/authors`}>
                                Authors
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["clients_narrators_index"]) && (
                        <Menu.Item key="narrators">
                            <NavLink onClick={toggleCollapsed} to={`${path}/clients/narrators`}>
                                Narrators
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["clients_publishers_index"]) && (
                        <Menu.Item key="publishers">
                            <NavLink onClick={toggleCollapsed} to={`${path}/clients/publishers`}>
                                Publishers
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["clients_producers_index"]) && (
                        <Menu.Item key="producers">
                            <NavLink onClick={toggleCollapsed} to={`${path}/clients/producers`}>
                                Producers
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            )}
            {Scope.checkScopesParent(["reports_"]) && (
                <SubMenu key="reports" icon={!topMenu && <FeatherIcon icon="pie-chart" />} title="Reports">
                    {Scope.checkScopes(["reports_user_subscriptions_index"]) && (
                        <Menu.Item key="user_subscriptions">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/user-subscriptions`}>
                                User Subscriptions
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_user_libraries_index"]) && (
                        <Menu.Item key="user-libraries">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/user-libraries`}>
                                User Libraries
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_user_wallets_index"]) && (
                        <Menu.Item key="user_wallets">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/user-wallets`}>
                                User wallets
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_listen_logs_index"]) && (
                        <Menu.Item key="listen-logs">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/listen-logs`}>
                                Listen Logs
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_active_users_index"]) && (
                        <Menu.Item key="active-users">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/active-users`}>
                                Active Users
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_top_books_index"]) && (
                        <Menu.Item key="top-books">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/top-books`}>
                                Top Books
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_top_authors_index"]) && (
                        <Menu.Item key="top-authors">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/top-authors`}>
                                Top Authors
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_top_narrators_index"]) && (
                        <Menu.Item key="top-narrators">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/top-narrators`}>
                                Top Narrators
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_top_publishers_index"]) && (
                        <Menu.Item key="top-publishers">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/top-publishers`}>
                                Top Publishers
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(["reports_top_producers_index"]) && (
                        <Menu.Item key="top-producers">
                            <NavLink onClick={toggleCollapsed} to={`${path}/reports/top-producers`}>
                                Top Producers
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            )}
        </Menu>
    );
};

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func
};

export default MenuItems;
