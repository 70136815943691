const actions = {
    FETCH_EPISODES    : 'FETCH_EPISODES',
    FETCH_EPISODE      : 'FETCH_EPISODE',
    RESET_EPISODE      : 'RESET_EPISODE',

    fetchEpisodesAction: (data) => {
        return {
            type: actions.FETCH_EPISODES,
            data: data
        };
    },
    resetEpisodeFormAction: () => {
        return {
            type: actions.RESET_EPISODE,
            data: null
        };
    },
    fetchEpisodeAction: (data) => {
        return {
            type: actions.FETCH_EPISODE,
            data: data
        };
    },
}

export default actions;
