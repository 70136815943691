const actions = {
    LOADING_START       : 'LOADING_START',
    LOADING_END         : 'LOADING_END',

    FETCH_UTILITIES_ROLES   : 'FETCH_UTILITIES_ROLES',
    FETCH_UTILITIES_USER    : 'FETCH_UTILITIES_USER',
    FETCH_UTILITIES_USERS   : 'FETCH_UTILITIES_USERS',

    FETCH_UTILITIES_COUNTRIES   : 'FETCH_UTILITIES_COUNTRIES',
    FETCH_UTILITIES_CITIES      : 'FETCH_UTILITIES_CITIES',
    FETCH_UTILITIES_LOCATIONS   : 'FETCH_UTILITIES_LOCATIONS',
    FETCH_UTILITIES_CATEGORIES  : 'FETCH_UTILITIES_CATEGORIES',
    FETCH_UTILITIES_HIGHLIGHTS  : 'FETCH_UTILITIES_HIGHLIGHTS',
    FETCH_UTILITIES_TAGS        : 'FETCH_UTILITIES_TAGS',

    FETCH_UTILITIES_ROYALTYUSERS     : 'FETCH_UTILITIES_ROYALTYUSERS',
    FETCH_UTILITIES_AUTHORS     : 'FETCH_UTILITIES_AUTHORS',
    FETCH_UTILITIES_NARRATORS   : 'FETCH_UTILITIES_NARRATORS',
    FETCH_UTILITIES_PUBLISHERS  : 'FETCH_UTILITIES_PUBLISHERS',
    FETCH_UTILITIES_PRODUCTIONS : 'FETCH_UTILITIES_PRODUCTIONS',
    FETCH_UTILITIES_PAYMENTS    : 'FETCH_UTILITIES_PAYMENTS',
    FETCH_UTILITIES_SUBSCRIPTIONS   : 'FETCH_UTILITIES_SUBSCRIPTIONS',

    LoadingStart: () => {
        return {
            type: actions.LOADING_START,
        };
    },
    LoadingEnd: data => {
        return {
            type: actions.LOADING_END
        };
    },
    fetchRolesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_ROLES,
            data: data
        };
    },
    fetchUserAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_USER,
            data: data
        };
    },
    fetchUsersAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_USERS,
            data: data
        };
    },
    fetchCountriesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_COUNTRIES,
            data: data
        }
    },
    fetchCitiesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CITIES,
            data: data
        }
    },
    fetchLocationsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_LOCATIONS,
            data: data
        }
    },
    fetchCategoriesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CATEGORIES,
            data: data
        }
    },
    fetchHighlightsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_HIGHLIGHTS,
            data: data
        }
    },
    fetchTagsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_TAGS,
            data: data
        }
    },
    fetchRoyaltyUsersAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_ROYALTYUSERS,
            data: data
        }
    },
    fetchAuthorsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_AUTHORS,
            data: data
        }
    },
    fetchNarratorsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_NARRATORS,
            data: data
        }
    },
    fetchPublishersAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_PUBLISHERS,
            data: data
        }
    },
    fetchProducersAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_PRODUCTIONS,
            data: data
        }
    },
    fetchPaymentsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_PAYMENTS,
            data: data
        }
    },
    fetchSubscriptionsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_SUBSCRIPTIONS,
            data: data
        }
    }
};

export default actions;
