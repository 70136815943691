const actions = {
    FETCH_PRODUCERS : 'FETCH_PRODUCERS',

    fetchProducersAction: (data) => {
        return {
            type: actions.FETCH_PRODUCERS,
            data: data
        };
    },
}

export default actions;
