const actions = {
    FETCH_AUTHORS : 'FETCH_AUTHORS',

    fetchAuthorsAction: (data) => {
        return {
            type: actions.FETCH_AUTHORS,
            data: data
        };
    },
}

export default actions;
