import actions from "./actions";

const {
    FETCH_USER_LIBRARIES,
    FETCH_USER_WALLETS,
    FETCH_LISTEN_LOGS,
    FETCH_USER_SUBSCRIPTIONS,
    FETCH_ACTIVE_USERS,
    FETCH_TOP_AUTHORS,
    FETCH_TOP_PUBLISHERS,
    FETCH_TOP_PRODUCERS,
    FETCH_TOP_NARRATORS,
    FETCH_TOP_BOOKS
} = actions;
const initState = {
    userSubscriptions: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    userWallets: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    userLibraries: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    listenLogs:{
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    activeUsers:{
      page: 0,
      perPage: 10,
      data: [],
      total: 0
    },
    topAuthors:{
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    topProducers:{
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    topPublishers:{
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    topNarrators:{
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    topBooks:{
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },

};

const reportsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_USER_SUBSCRIPTIONS:
            return {
                ...state,
                userSubscriptions: data
            }
        case FETCH_USER_WALLETS:
            return {
                ...state,
                userWallets: data
            }
        case FETCH_USER_LIBRARIES:
            return {
                ...state,
                userLibraries: data
            }
        case FETCH_LISTEN_LOGS:
            return {
                ...state,
                listenLogs: data
            }
        case FETCH_ACTIVE_USERS:
            return {
                ...state,
                activeUsers: data
            }
        case FETCH_TOP_AUTHORS:
            return {
                ...state,
                topAuthors: data
            }
        case FETCH_TOP_PUBLISHERS:
            return {
                ...state,
                topPublishers: data
            }
        case FETCH_TOP_PRODUCERS:
            return {
                ...state,
                topProducers: data
            }
        case FETCH_TOP_NARRATORS:
            return {
                ...state,
                topNarrators: data
            }
        case FETCH_TOP_BOOKS:
            return {
                ...state,
                topBooks: data
            }
        default:
            return state;
    }
}

export default reportsReducer;
