import actions from "./actions";

const {FETCH_PODCAST, FETCH_PODCASTS, RESET_PODCAST} = actions;
const initState = {
    podcasts: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    podcast: {_id: null}
};

const PodcastReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PODCASTS:
            return {
                ...state,
                podcasts: data
            }
        case RESET_PODCAST:
            return {
                ...state,
                podcast: initState.podcast,
            };
        case FETCH_PODCAST:
            return {
                ...state,
                podcast: data,
            };
        default:
            return state;
    }
}

export default PodcastReducer;
