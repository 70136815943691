import actions from "./actions";

const {FETCH_USERS, FETCH_USER_INFO, RESET_USER_INFO} = actions;
const initState = {
    users: {
        page: 1,
        perPage: 10,
        data: [],
        total: 0
    },

    userInfo: null,
    userLibraries: [],
    userSubscriptions: [],
    userPayments: [],
    userReviews: [],
    authorBooks: [],
    authorPurchases: [],
    narratorBooks: [],
    narratorPurchases: [],
    publisherBooks: [],
    publisherPurchases: [],
    producerBooks: [],
    producerPurchases: [],
};

const UsersReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_USERS:
            return {
                ...state,
                users: data
            }
        case FETCH_USER_INFO:
            return {
                ...state,
                userInfo: data.userInfo,
                userLibraries: data.userLibraries,
                userSubscriptions: data.userSubscriptions,
                userPayments: data.userPayments,
                userReviews: data.userReviews,
                authorBooks: data.authorBooks,
                authorPurchases: data.authorPurchases,
                narratorBooks: data.narratorBooks,
                narratorPurchases: data.narratorPurchases,
                publisherBooks: data.publisherBooks,
                publisherPurchases: data.publisherPurchases,
                producerBooks: data.producerBooks,
                producerPurchases: data.producerPurchases,
            }
        case RESET_USER_INFO:
            return {
                ...state,
                userInfo: null
            }
        default:
            return state;
    }
}

export default UsersReducer;
