import { combineReducers } from 'redux';

import authReducer from './authentication/reducers';
import utilitiesReducer from './utilities/reducers';
import dashboardReducer from './dashboard/reducers';

import roleReducer from './userManagement/roles/reducers';
import userReducer from './userManagement/users/reducers';
import departmentReducer from './userManagement/departments/reducers';
import teamReducer from './userManagement/teams/reducers';

import wsCategoryReducer from './webSetup/categories/reducers';
import wsCountriesReducer from './webSetup/countries/reducers';
import wsCitiesReducer from './webSetup/cities/reducers';
import wsLocationsReducer from './webSetup/locations/reducers';
import wsTagsReducer from './webSetup/tags/reducers';
import wsBannersReducer from './webSetup/banners/reducers';
import wsHighlightsReducer from './webSetup/highlights/reducers';

import workspaceBooksReducer from './workspace/books/reducers';
import workspacePodcastsReducer from './workspace/podcasts/reducers';
import workspaceChaptersReducer from './workspace/chapters/reducers';
import workspaceEpisodesReducer from './workspace/episodes/reducers';
import workspaceCouponsReducer from './workspace/coupons/reducers';
import workspaceSubscriptionsReducer from './workspace/subscriptions/reducers';
import workspaceAdvertisementsReducer from './workspace/advertisements/reducers';
import workspaceReviewsReducer from './workspace/reviews/reducers';
import workspacePaymentsReducer from './workspace/payments/reducers';
import workspaceReferralsReducer from './workspace/referrals/reducers';
import workspaceWithdrawalsReducer from './workspace/withdrawals/reducers';

import clientsUsersReducer from './clients/users/reducers';
import clientsAuthorsReducer from './clients/authors/reducers';
import clientsNarratorsReducer from './clients/narrators/reducers';
import clientsPublishersReducer from './clients/publishers/reducers';
import clientsProducersReducer from './clients/producers/reducers';

import reportsReducer from './reports/reducers';

import ChangeLayoutMode from './themeLayout/reducers';
import chartContentReducer from './chartContent/reducers';
import Profile from './profile/reducers';

const rootReducers = combineReducers({
    auth: authReducer,
    utilities: utilitiesReducer,
    dashboard: dashboardReducer,

    umRoles: roleReducer,
    umUsers: userReducer,
    umDepartments: departmentReducer,
    umTeams: teamReducer,

    wsCategories: wsCategoryReducer,
    wsCountries: wsCountriesReducer,
    wsCities: wsCitiesReducer,
    wsLocations: wsLocationsReducer,
    wsTags: wsTagsReducer,
    wsBanners: wsBannersReducer,
    wsHighlights: wsHighlightsReducer,

    workspaceBooks: workspaceBooksReducer,
    workspacePodcasts: workspacePodcastsReducer,
    workspaceChapters: workspaceChaptersReducer,
    workspaceEpisodes: workspaceEpisodesReducer,
    workspaceCoupons: workspaceCouponsReducer,
    workspaceSubscriptions: workspaceSubscriptionsReducer,
    workspaceAdvertisements: workspaceAdvertisementsReducer,
    workspaceReviews: workspaceReviewsReducer,
    workspacePayments: workspacePaymentsReducer,
    workspaceReferrals: workspaceReferralsReducer,
    workspaceWithdrawals: workspaceWithdrawalsReducer,

    clientsUsers: clientsUsersReducer,
    clientsAuthors: clientsAuthorsReducer,
    clientsNarrators: clientsNarratorsReducer,
    clientsPublishers: clientsPublishersReducer,
    clientsProducers: clientsProducersReducer,

    userReports: reportsReducer,

    ChangeLayoutMode,
    chartContent: chartContentReducer,
    Profile,
});

export default rootReducers;
