const actions = {
    FETCH_USERS_STATS : 'FETCH_USERS_STATS',
    FETCH_SUBSCRIPTIONS_STATS : 'FETCH_SUBSCRIPTIONS_STATS',
    FETCH_PURCHASES_STATS : 'FETCH_PURCHASES_STATS',
    FETCH_LIBRARIES_STATS : 'FETCH_LIBRARIES_STATS',
    FETCH_REVENUE_STATS : 'FETCH_REVENUE_STATS',
    FETCH_AUTHORS_STATS : 'FETCH_AUTHORS_STATS',
    FETCH_NARRATORS_STATS : 'FETCH_NARRATORS_STATS',
    FETCH_PUBLISHERS_STATS : 'FETCH_PUBLISHERS_STATS',
    FETCH_PRODUCERS_STATS : 'FETCH_PRODUCERS_STATS',
    FETCH_LISTEN_BOOKS_STATS : 'FETCH_LISTEN_BOOKS_STATS',
    FETCH_LISTEN_TIME_STATS : 'FETCH_LISTEN_TIME_STATS',
    FETCH_ACTIVITIES_STATS : 'FETCH_ACTIVITIES_STATS',
    FETCH_HITS_STATS : 'FETCH_HITS_STATS',
    FETCH_BOOKS_STATS : 'FETCH_BOOKS_STATS',
    FETCH_PODCASTS_STATS : 'FETCH_PODCASTS_STATS',
    FETCH_PODCAST_LISTEN_BOOKS_STATS : 'FETCH_PODCAST_LISTEN_BOOKS_STATS',
    FETCH_PODCAST_LISTEN_TIME_STATS : 'FETCH_PODCAST_LISTEN_TIME_STATS',

    fetchUsersStatsAction: (data) => {
        return {
            type: actions.FETCH_USERS_STATS,
            data: data
        };
    },
    fetchSubscriptionsStatsAction: (data) => {
        return {
            type: actions.FETCH_SUBSCRIPTIONS_STATS,
            data: data
        };
    },
    fetchPurchasesStatsAction: (data) => {
        return {
            type: actions.FETCH_PURCHASES_STATS,
            data: data
        };
    },
    fetchLibrariesStatsAction: (data) => {
        return {
            type: actions.FETCH_LIBRARIES_STATS,
            data: data
        };
    },
    fetchRevenueStatsAction: (data) => {
        return {
            type: actions.FETCH_REVENUE_STATS,
            data: data
        };
    },
    fetchAuthorsStatsAction: (data) => {
        return {
            type: actions.FETCH_AUTHORS_STATS,
            data: data
        };
    },
    fetchNarratorsStatsAction: (data) => {
        return {
            type: actions.FETCH_NARRATORS_STATS,
            data: data
        };
    },
    fetchPublishersStatsAction: (data) => {
        return {
            type: actions.FETCH_PUBLISHERS_STATS,
            data: data
        };
    },
    fetchProducersStatsAction: (data) => {
        return {
            type: actions.FETCH_PRODUCERS_STATS,
            data: data
        };
    },
    fetchListenBooksStatsAction: (data) => {
        return {
            type: actions.FETCH_LISTEN_BOOKS_STATS,
            data: data
        };
    },
    fetchListenTimeStatsAction: (data) => {
        return {
            type: actions.FETCH_LISTEN_TIME_STATS,
            data: data
        };
    },
    fetchActivitiesStatsAction: (data) => {
        return {
            type: actions.FETCH_ACTIVITIES_STATS,
            data: data
        };
    },
    fetchHitsStatsAction: (data) => {
        return {
            type: actions.FETCH_HITS_STATS,
            data: data
        };
    },
    fetchBooksStatsAction: (data) => {
        return {
            type: actions.FETCH_BOOKS_STATS,
            data: data
        };
    },
    fetchPodcastsStatsAction: (data) => {
        return {
            type: actions.FETCH_PODCASTS_STATS,
            data: data
        };
    },
    fetchPodcastListenBooksStatsAction: (data) => {
        return {
            type: actions.FETCH_PODCAST_LISTEN_BOOKS_STATS,
            data: data
        };
    },
    fetchPodcastListenTimeStatsAction: (data) => {
        return {
            type: actions.FETCH_PODCAST_LISTEN_TIME_STATS,
            data: data
        };
    },
};

export default actions;
