const actions = {
    FETCH_USER_SUBSCRIPTIONS : 'FETCH_USER_SUBSCRIPTIONS',
    FETCH_USER_WALLETS : 'FETCH_USER_WALLETS',
    FETCH_USER_LIBRARIES : 'FETCH_USER_LIBRARIES',
    FETCH_ACTIVE_USERS : 'FETCH_ACTIVE_USERS',
    FETCH_TOP_AUTHORS : 'FETCH_TOP_AUTHORS',
    FETCH_TOP_PUBLISHERS : 'FETCH_TOP_PUBLISHERS',
    FETCH_TOP_PRODUCERS : 'FETCH_TOP_PRODUCES',
    FETCH_TOP_NARRATORS : "FETCH_TOP_NARRATORS",
    FETCH_TOP_BOOKS : "FETCH_TOP_BOOKS",
    FETCH_LISTEN_LOGS : "FETCH_LISTEN_LOGS",


    fetchUserSubscriptionsAction: (data) => {
        return {
            type: actions.FETCH_USER_SUBSCRIPTIONS,
            data: data
        };
    },
    fetchUserWalletsAction: (data) => {
        return {
            type: actions.FETCH_USER_WALLETS,
            data: data
        };
    },
    fetchUserLibrariesAction: (data) => {
        return {
            type: actions.FETCH_USER_LIBRARIES,
            data: data
        };
    },
    fetchListenLogsAction: (data) => {
        return {
            type: actions.FETCH_LISTEN_LOGS,
            data: data
        };
    },
    fetchActiveUsersAction:(data) => {
        return {
            type: actions.FETCH_ACTIVE_USERS,
            data: data
        }
    },
    fetchTopAuthorsAction:(data) => {
      return {
            type: actions.FETCH_TOP_AUTHORS,
            data: data
      }
    },
    fetchTopPublishersAction:(data) => {
      return {
            type: actions.FETCH_TOP_PUBLISHERS,
            data: data
        }
    },
    fetchTopProducersAction:(data) => {
        return {
            type: actions.FETCH_TOP_PRODUCERS,
            data: data
        }
    },
    fetchTopNarratorsAction:(data) => {
        return {
            type: actions.FETCH_TOP_NARRATORS,
            data: data
        }
    },
    fetchTopBooksAction:(data) => {
        return {
            type: actions.FETCH_TOP_BOOKS,
            data: data
        }
    },
}



export default actions;
