const actions = {
    FETCH_NARRATORS : 'FETCH_NARRATORS',

    fetchNarratorsAction: (data) => {
        return {
            type: actions.FETCH_NARRATORS,
            data: data
        };
    },
}

export default actions;
