import actions from './actions';

const {
    LOADING_START, LOADING_END,
    FETCH_UTILITIES_ROLES, FETCH_UTILITIES_USER, FETCH_UTILITIES_USERS,
    FETCH_UTILITIES_LOCATIONS, FETCH_UTILITIES_CITIES, FETCH_UTILITIES_COUNTRIES,
    FETCH_UTILITIES_CATEGORIES, FETCH_UTILITIES_HIGHLIGHTS, FETCH_UTILITIES_TAGS, FETCH_UTILITIES_PAYMENTS,
    FETCH_UTILITIES_AUTHORS, FETCH_UTILITIES_NARRATORS, FETCH_UTILITIES_PUBLISHERS, FETCH_UTILITIES_PRODUCTIONS,
    FETCH_UTILITIES_ROYALTYUSERS,
    FETCH_UTILITIES_SUBSCRIPTIONS
} = actions;

const initState = {
    loading: false,
    user: {_id: null},
    users: [],
    roles: [],
    payments: [],
    countries: [], cities: [], locations: [],
    categories: [], highlights: [], tags: [],
    authors: [], narrators: [], publishers: [], producers: [], royaltyUsers: [],
    subscriptions: []
};

const UtilitiesReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case LOADING_START:
            return {
                ...state,
                loading: true,
            };
        case LOADING_END:
            return {
                ...state,
                loading: false,
            };
        case FETCH_UTILITIES_ROLES:
            return {
                ...state,
                roles: data,
            };
        case FETCH_UTILITIES_USER:
            return {
                ...state,
                user: data,
            };
        case FETCH_UTILITIES_USERS:
            return {
                ...state,
                users: data,
            };
        case FETCH_UTILITIES_COUNTRIES:
            return {
                ...state,
                countries: data,
            };
        case FETCH_UTILITIES_CITIES:
            return {
                ...state,
                cities: data,
            };
        case FETCH_UTILITIES_LOCATIONS:
            return {
                ...state,
                locations: data,
            };
        case FETCH_UTILITIES_CATEGORIES:
            return {
                ...state,
                categories: data,
            };
        case FETCH_UTILITIES_HIGHLIGHTS:
            return {
                ...state,
                highlights: data,
            };
        case FETCH_UTILITIES_TAGS:
            return {
                ...state,
                tags: data,
            };
        case FETCH_UTILITIES_ROYALTYUSERS:
            return {
                ...state,
                royaltyUsers: data,
            };
        case FETCH_UTILITIES_AUTHORS:
            return {
                ...state,
                authors: data,
            };
        case FETCH_UTILITIES_NARRATORS:
            return {
                ...state,
                narrators: data,
            };
        case FETCH_UTILITIES_PUBLISHERS:
            return {
                ...state,
                publishers: data,
            };
        case FETCH_UTILITIES_PRODUCTIONS:
            return {
                ...state,
                producers: data,
            };
        case FETCH_UTILITIES_PAYMENTS:
            return {
                ...state,
                payments: data,
            };
        case FETCH_UTILITIES_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: data,
            }
        default:
            return state;
    }
};
export default UtilitiesReducer;
