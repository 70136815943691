import actions from "./actions";

const {FETCH_REVIEW, FETCH_REVIEWS} = actions;
const initState = {
    reviews: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    review: {_id: null}
};

const ReviewReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_REVIEWS:
            return {
                ...state,
                reviews: data
            }
        case FETCH_REVIEW:
            return {
                ...state,
                review: data,
            };
        default:
            return state;
    }
}

export default ReviewReducer;
