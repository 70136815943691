const actions = {
    FETCH_WITHDRAWALS: 'FETCH_WITHDRAWALS',
    RESET_WITHDRAWAL: 'RESET_WITHDRAWAL',
    FETCH_WITHDRAWAL: 'FETCH_WITHDRAWAL',

    fetchWithdrawalsAction: (data) => {
        return {
            type: actions.FETCH_WITHDRAWALS,
            data: data
        };
    },
    resetWithdrawalAction: () => {
        return {
            type: actions.RESET_WITHDRAWAL,
            data: null
        };
    },
    fetchWithdrawalAction: (data) => {
        return {
            type: actions.FETCH_WITHDRAWAL,
            data: data
        };
    },
}

export default actions;
