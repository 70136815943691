import actions from "./actions";

const {FETCH_SUBSCRIPTION, FETCH_SUBSCRIPTIONS, RESET_SUBSCRIPTION} = actions;
const initState = {
    subscriptions: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    subscription: {_id: null}
};

const SubscriptionReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: data
            }
        case RESET_SUBSCRIPTION:
            return {
                ...state,
                subscription: initState.subscription,
            };
        case FETCH_SUBSCRIPTION:
            return {
                ...state,
                subscription: data,
            };
        default:
            return state;
    }
}

export default SubscriptionReducer;
