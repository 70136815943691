import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const UserLibraries =  lazy(() => import('../../container/reports/userLibraries/index'));
const UserSubscriptions = lazy(() => import('../../container/reports/userSubscriptions/index'));
const UserWallets = lazy(() => import('../../container/reports/userWallets/index'));
const ActiveUsers = lazy(() => import('../../container/reports/activeUsers/index'))
const TopAuthors = lazy(() => import('../../container/reports/topAuthors/index'))
const TopNarrators = lazy(() => import('../../container/reports/topNarrators/index'))
const TopProducers = lazy(() => import('../../container/reports/topProducers/index'))
const TopPublishers = lazy(() => import('../../container/reports/topPublishers/index'))
const TopBooks = lazy(() => import('../../container/reports/topBooks/index'))
const ListenLogs = lazy(() => import('../../container/reports/listenLogs/index'));


const ReportsRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/user-libraries`} component={UserLibraries}/>
            <Route path={`${path}/listen-logs`} component={ListenLogs}/>
            <Route path={`${path}/user-wallets`} component={UserWallets}/>
            <Route path={`${path}/user-subscriptions`} component={UserSubscriptions}/>
            <Route path={`${path}/active-users`} component={ActiveUsers} />
            <Route path={`${path}/top-authors`} component={TopAuthors} />
            <Route path={`${path}/top-narrators`} component={TopNarrators} />
            <Route path={`${path}/top-producers`} component={TopProducers} />
            <Route path={`${path}/top-publishers`} component={TopPublishers} />
            <Route path={`${path}/top-books`} component={TopBooks} />
        </Switch>
    );
}

export default ReportsRoutes;