const actions = {
    FETCH_HIGHLIGHTS    : 'FETCH_HIGHLIGHTS',
    FETCH_HIGHLIGHT      : 'FETCH_HIGHLIGHT',
    RESET_HIGHLIGHT      : 'RESET_HIGHLIGHT',

    fetchHighlightsAction: (data) => {
        return {
            type: actions.FETCH_HIGHLIGHTS,
            data: data
        };
    },
    resetHighlightFormAction: () => {
        return {
            type: actions.RESET_HIGHLIGHT,
            data: null
        };
    },
    fetchHighlightAction: (data) => {
        return {
            type: actions.FETCH_HIGHLIGHT,
            data: data
        };
    },
}

export default actions;
