import actions from './actions';

const {
    FETCH_USERS_STATS,
    FETCH_SUBSCRIPTIONS_STATS,
    FETCH_PURCHASES_STATS,
    FETCH_LIBRARIES_STATS,
    FETCH_REVENUE_STATS,
    FETCH_AUTHORS_STATS,
    FETCH_NARRATORS_STATS,
    FETCH_PUBLISHERS_STATS,
    FETCH_PRODUCERS_STATS,
    FETCH_LISTEN_BOOKS_STATS,
    FETCH_LISTEN_TIME_STATS,
    FETCH_ACTIVITIES_STATS,
    FETCH_HITS_STATS,
    FETCH_BOOKS_STATS,
    FETCH_PODCASTS_STATS,
    FETCH_PODCAST_LISTEN_BOOKS_STATS,
    FETCH_PODCAST_LISTEN_TIME_STATS,
} = actions;

const initState = {
    usersStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    subscriptionsStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    purchasesStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    librariesStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    revenueStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    authorsStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    narratorsStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    publishersStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    producersStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    listenBooksStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    listenTimeStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    activitiesStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    hitsStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    booksStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    podcastsStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    listenPodcastBooksStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
    listenPodcastTimeStats: {
        total: 0,
        percentage: 0,
        stats: []
    },
};

const DashboardReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_USERS_STATS:
            return {
                ...state,
                usersStats: data,
            };
        case FETCH_SUBSCRIPTIONS_STATS:
            return {
                ...state,
                subscriptionsStats: data,
            };
        case FETCH_PURCHASES_STATS:
            return {
                ...state,
                purchasesStats: data,
            };
        case FETCH_LIBRARIES_STATS:
            return {
                ...state,
                librariesStats: data,
            };
        case FETCH_REVENUE_STATS:
            return {
                ...state,
                revenueStats: data,
            };
        case FETCH_AUTHORS_STATS:
            return {
                ...state,
                authorsStats: data,
            };
        case FETCH_NARRATORS_STATS:
            return {
                ...state,
                narratorsStats: data,
            };
        case FETCH_PUBLISHERS_STATS:
            return {
                ...state,
                publishersStats: data,
            };
        case FETCH_PRODUCERS_STATS:
            return {
                ...state,
                producersStats: data,
            };
        case FETCH_LISTEN_BOOKS_STATS:
            return {
                ...state,
                listenBooksStats: data,
            };
        case FETCH_LISTEN_TIME_STATS:
            return {
                ...state,
                listenTimeStats: data,
            };
        case FETCH_ACTIVITIES_STATS:
            return {
                ...state,
                activitiesStats: data,
            };
        case FETCH_HITS_STATS:
            return {
                ...state,
                hitsStats: data,
            };
        case FETCH_BOOKS_STATS:
            return {
                ...state,
                booksStats: data,
            };
        case FETCH_PODCASTS_STATS:
            return {
                ...state,
                podcastsStats: data,
            };
        case FETCH_PODCAST_LISTEN_BOOKS_STATS:
            return {
                ...state,
                listenPodcastBooksStats: data,
            };
        case FETCH_PODCAST_LISTEN_TIME_STATS:
            return {
                ...state,
                listenPodcastTimeStats: data,
            };
        default:
            return state;
    }
};

export default DashboardReducer;
