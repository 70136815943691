import actions from "./actions";

const {FETCH_HIGHLIGHT, FETCH_HIGHLIGHTS, RESET_HIGHLIGHT} = actions;
const initState = {
    highlights: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    highlight: {_id: null}
};

const HighlightReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_HIGHLIGHTS:
            return {
                ...state,
                highlights: data
            }
        case RESET_HIGHLIGHT:
            return {
                ...state,
                highlight: initState.highlight,
            };
        case FETCH_HIGHLIGHT:
            return {
                ...state,
                highlight: data,
            };
        default:
            return state;
    }
}

export default HighlightReducer;
