export class Constants {
    // static BASE_ENDPOINT = 'https://staging-api.shunboi.com/';
    static BASE_ENDPOINT = 'https://new-api.shunboi.com/';
    // static BASE_ENDPOINT = 'http://localhost:3333/';

    static CLIENT_ID = 'app-client';
    static CLIENT_SECRET = '6$dMwkMbY9vR^Tl25RA&so4O%@AWea';
    // static BASE_MEDIA_URL = 'https://staging-api.shunboi.com/';
    static BASE_MEDIA_URL = 'https://new-api.shunboi.com/';
    // static BASE_MEDIA_URL = 'http://localhost:3333/';

    static STORAGE_ACCESS_TOKEN = 'accessToken';
    static STORAGE_REFRESH_TOKEN = 'refreshToken';
    static STORAGE_USER_INFO = 'userInfo';
    static STORAGE_USER_SCOPES = 'userScopes';
    static STORAGE_USER_LOGGED_IN = 'userLoggedIn';

    static S3_BUCKET_NAME = 'shunboi-test';
    static S3_DIR_NAME = 'admin-files';
    static S3_REGION = 'ap-southeast-1';
    static S3_ACCESS_KEY_ID = 'AKIAZZYPHRVU6VWA4KHZ';
    static S3_ACCESS_KEY_SECRET = 'KAsBp47PDxBk0H1aJLrMSU4uxaSRkwdpWaiR44Sr';

    static S3_FILE_NAME = (key) => `${+ new Date()}-${key.replace(/[ ,]/g, "-")}`;
    static S3_CLIENT = (key) => `${this.S3_DIR_NAME}/client/${key.replace(/[ ,.]/g, "-")}`;
    static S3_WEB_SETUP_DIR = (key) => `${this.S3_DIR_NAME}/web-setup/${key.replace(/[ ,.]/g, "-")}`;
    static S3_BOOKS_THUMBNAIL_DIR = (key) => `${this.S3_DIR_NAME}/books/thumbnail/${key.replace(/[ ,.]/g, "-")}`;
    static S3_BOOKS_AUDIO_DIR = (key) => `${this.S3_DIR_NAME}/books/audio/${key.replace(/[ ,.]/g, "-")}`;
    static S3_COUPONS_THUMBNAIL_DIR = (key) => `${this.S3_DIR_NAME}/coupons/${key.replace(/[ ,.]/g, "-")}`;
    static S3_COUPONS_ADVERTISEMENT_DIR = (key) => `${this.S3_DIR_NAME}/advertisement/${key.replace(/[ ,.]/g, "-")}`;
    static S3_BASE_URL = (key) => `https://shunboi-test.s3.ap-southeast-1.amazonaws.com/${key}`;

    static AUTH = this.BASE_ENDPOINT + 'back-end/auth/';
    static UTILITIES = this.BASE_ENDPOINT + 'back-end/utilities/';
    static DASHBOARD = this.BASE_ENDPOINT + 'back-end/dashboard/';

    static USER_MANAGEMENT = this.BASE_ENDPOINT + 'back-end/user-management/';

    static WEB_SETUP = this.BASE_ENDPOINT + 'back-end/web-setup/';
    static CLIENTS = this.BASE_ENDPOINT + 'back-end/clients/';
    static WORKSPACE = this.BASE_ENDPOINT + 'back-end/workspace/';
    static REPORTS = this.BASE_ENDPOINT + 'back-end/reports/';

    static WEEKDAYS = [
        { label: "Weekday", value: "" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" }
    ];

    static PAGES = [
        { label: "book", value: "book" },
        { label: "author", value: "author" },
        { label: "narrator", value: "narrator" },
        { label: "producer", value: "producer" },
        { label: "publisher", value: "publisher" },
        { label: "search", value: "search" },
        { label: "premium", value: "premium" }
    ];

    static STATUS = [
        { label: "Status", value: "" },
        { label: "Available", value: "active" },
        { label: "Unavailable", value: "inactive" },
    ];

    static DISCOUNT_TYPES = [
        { name: 'Percentage', value: 'percentage' },
        { name: 'Flat', value: 'flat' },
    ]

    static orderStatus = [
        { label: "requested", value: "requested" },
        { label: "disbursed", value: "disbursed" }
    ];
}
