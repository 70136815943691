import actions from "./actions";

const {FETCH_BOOK, FETCH_BOOKS, RESET_BOOK} = actions;
const initState = {
    books: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    book: {_id: null}
};

const BookReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_BOOKS:
            return {
                ...state,
                books: data
            }
        case RESET_BOOK:
            return {
                ...state,
                book: initState.book,
            };
        case FETCH_BOOK:
            return {
                ...state,
                book: data,
            };
        default:
            return state;
    }
}

export default BookReducer;
