const actions = {
    FETCH_ADVERTISEMENTS    : 'FETCH_ADVERTISEMENTS',
    FETCH_ADVERTISEMENT      : 'FETCH_ADVERTISEMENT',
    RESET_ADVERTISEMENT      : 'RESET_ADVERTISEMENT',

    fetchAdvertisementsAction: (data) => {
        return {
            type: actions.FETCH_ADVERTISEMENTS,
            data: data
        };
    },
    resetAdvertisementFormAction: () => {
        return {
            type: actions.RESET_ADVERTISEMENT,
            data: null
        };
    },
    fetchAdvertisementAction: (data) => {
        return {
            type: actions.FETCH_ADVERTISEMENT,
            data: data
        };
    },
}

export default actions;
