import actions from "./actions";

const {FETCH_EPISODE, FETCH_EPISODES, RESET_EPISODE} = actions;
const initState = {
    episodes: [],
    episode: {_id: null}
};

const EpisodeReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_EPISODES:
            return {
                ...state,
                episodes: data
            }
        case RESET_EPISODE:
            return {
                ...state,
                episode: initState.episode,
            };
        case FETCH_EPISODE:
            return {
                ...state,
                episode: data,
            };
        default:
            return state;
    }
}

export default EpisodeReducer;
