const actions = {
    FETCH_PODCASTS    : 'FETCH_PODCASTS',
    FETCH_PODCAST      : 'FETCH_PODCAST',
    RESET_PODCAST      : 'RESET_PODCAST',

    fetchPodcastsAction: (data) => {
        return {
            type: actions.FETCH_PODCASTS,
            data: data
        };
    },
    resetPodcastFormAction: () => {
        return {
            type: actions.RESET_PODCAST,
            data: null
        };
    },
    fetchPodcastAction: (data) => {
        return {
            type: actions.FETCH_PODCAST,
            data: data
        };
    },
}

export default actions;
