import actions from "./actions";

const { FETCH_REFERRALS } = actions;
const initState = {
    referrals: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    }
};

const ReferralsReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_REFERRALS:
            return {
                ...state,
                referrals: data
            }
        default:
            return state;
    }
}

export default ReferralsReducer;
