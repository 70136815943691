const actions = {
    FETCH_REFERRALS: 'FETCH_REFERRALS',

    fetchReferralsAction: (data) => {
        return {
            type: actions.FETCH_REFERRALS,
            data: data
        };
    },
}

export default actions;
