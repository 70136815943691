const actions = {
    FETCH_BOOKS    : 'FETCH_BOOKS',
    FETCH_BOOK      : 'FETCH_BOOK',
    RESET_BOOK      : 'RESET_BOOK',

    fetchBooksAction: (data) => {
        return {
            type: actions.FETCH_BOOKS,
            data: data
        };
    },
    resetBookFormAction: () => {
        return {
            type: actions.RESET_BOOK,
            data: null
        };
    },
    fetchBookAction: (data) => {
        return {
            type: actions.FETCH_BOOK,
            data: data
        };
    },
}

export default actions;
