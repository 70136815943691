import actions from "./actions";

const {FETCH_CHAPTER, FETCH_CHAPTERS, RESET_CHAPTER} = actions;
const initState = {
    chapters: [],
    chapter: {_id: null}
};

const ChapterReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_CHAPTERS:
            return {
                ...state,
                chapters: data
            }
        case RESET_CHAPTER:
            return {
                ...state,
                chapter: initState.chapter,
            };
        case FETCH_CHAPTER:
            return {
                ...state,
                chapter: data,
            };
        default:
            return state;
    }
}

export default ChapterReducer;
