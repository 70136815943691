import actions from "./actions";

const { FETCH_WITHDRAWALS, RESET_WITHDRAWAL, FETCH_WITHDRAWAL } = actions;
const initState = {
    withdrawals: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    withdrawal: { _id: null }
};

const WithdrawalsReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_WITHDRAWALS:
            return {
                ...state,
                withdrawals: data
            }
        case RESET_WITHDRAWAL:
            return {
                ...state,
                withdrawal: initState.withdrawal,
            };
        case FETCH_WITHDRAWAL:
            return {
                ...state,
                withdrawal: data,
            };
        default:
            return state;
    }
}

export default WithdrawalsReducer;
