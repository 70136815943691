import actions from "./actions";

const {FETCH_PRODUCERS} = actions;
const initState = {
    producers: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
};

const ProducersReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PRODUCERS:
            return {
                ...state,
                producers: data
            }
        default:
            return state;
    }
}

export default ProducersReducer;
