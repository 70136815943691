const actions = {
    FETCH_CHAPTERS    : 'FETCH_CHAPTERS',
    FETCH_CHAPTER      : 'FETCH_CHAPTER',
    RESET_CHAPTER      : 'RESET_CHAPTER',

    fetchChaptersAction: (data) => {
        return {
            type: actions.FETCH_CHAPTERS,
            data: data
        };
    },
    resetChapterFormAction: () => {
        return {
            type: actions.RESET_CHAPTER,
            data: null
        };
    },
    fetchChapterAction: (data) => {
        return {
            type: actions.FETCH_CHAPTER,
            data: data
        };
    },
}

export default actions;
