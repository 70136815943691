import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Books = lazy(() => import('../../container/workspace/books/index'));
const Chapters = lazy(() => import('../../container/workspace/chapters'));
const Podcasts = lazy(() => import('../../container/workspace/podcasts/index'));
const Episodes = lazy(() => import('../../container/workspace/episodes/index'));
const Coupons = lazy(() => import('../../container/workspace/coupons/index'));
const Subscriptions = lazy(() => import('../../container/workspace/subscriptions/index'));
const Advertisements = lazy(() => import('../../container/workspace/advertisements/index'));
const Reviews = lazy(() => import('../../container/workspace/reviews/index'));
const Payments = lazy(() => import('../../container/workspace/payments/index'));
const Referrals = lazy(() => import('../../container/workspace/referrals/index'));
const Withdrawals = lazy(() => import('../../container/workspace/withdrawals/index'));

const WebSetupRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/books`} component={Books} />
            <Route path={`${path}/chapters/:bookId`} component={Chapters} />
            <Route path={`${path}/podcasts`} component={Podcasts} />
            <Route path={`${path}/episodes/:episodesId`} component={Episodes} />
            <Route path={`${path}/coupons`} component={Coupons} />
            <Route path={`${path}/subscriptions`} component={Subscriptions} />
            <Route path={`${path}/advertisements`} component={Advertisements} />
            <Route path={`${path}/reviews`} component={Reviews} />
            <Route path={`${path}/payments`} component={Payments} />
            <Route path={`${path}/referrals`} component={Referrals} />
            <Route path={`${path}/withdrawals`} component={Withdrawals} />
        </Switch>
    );
};

export default WebSetupRoutes;
